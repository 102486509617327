body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.cursor-pointer {
  cursor: pointer;
}

@media screen and (max-width: 991px) {
  .ant-drawer-content-wrapper {
    width: 280px !important;
  }

  .ant-layout-sider {
    width: 0 !important;
    min-width: 0 !important;
    flex: 0 0 auto !important;
  }

  .ant-drawer-body {
    padding: 0px !important;
  }

  .ant-layout-content {
    padding: 10px !important;
  }
}

.ant-card {
  box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
}

.ant-table {
  th {
    background-color: #003366ec !important;
    color: #ffffff !important;
    border-inline-end: 1px solid #cccccc7e !important;
    border-bottom: 1px solid #cccccc7e !important;
  }
}

.ant-card-head-title {
  color: #003366;
  font-weight: bold;
  font-size: 18px;

  h4 {
    font-weight: bold;
  }
}
